/*
*pagination
*/
.pagination {
  justify-content: center;
  flex-wrap: wrap;
  li {
    margin-right: 61px;
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      a {
        background: $orange;
        color: $white;
      }
    }
    a {
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      border-radius: 50%;
      padding: 0px 2px;
      font-weight: 600;
      color: #606060;
      display: inline-block;
      text-align: center;
      &:hover {
        background: $orange;
        color: $white;
      }
    }
  }
}

@media (max-width: 767px) {
  .pagination {
    justify-content: space-between;
    li {
      margin-right: 0px;
    }
  }
}
