/*
*pagers
*/
.pager {
  @media (max-width: 767px){
    margin-top: 15px;
   }
  .page {
    font-size: rem-calc(12);
    line-height: calc(24 / 12);
    font-weight: 600;
    display: inline-block;
    margin-right: 12px;
    &:last-child {
      margin-right: 0px;
    }
    span {
      display: inline-block;
    }
  }
  .prev {
    @media (max-width: 767px){
      float:left;
     }
    span {
      margin-right: 12px;
    }
  }
  .next {
    @media (max-width: 767px){
      float:right;
     }
    span {
      margin-left: 12px;
    }
  }
}
