.services{
    .section {
        padding: 50px 0px;
    }
    .our-services{
        h5{
            font-size: rem-calc(14) ;
            text-transform: capitalize;
            color: #fd5f0e;
            font-weight: 600;
            line-height: calc(24 / 14);
            position: relative;
            margin-bottom: 24px;
            @media (max-width: 767px){
                margin-left: 30px;
                
            }
        }
        .text2{
            color: #2f4c72 !important;
            text-transform: uppercase !important;
            margin-bottom: 13px;
            @media (max-width: 767px){
                margin-left: 0px;
            }
        }
        @media (max-width: 767px){
            margin-left: 20px;
            margin-right: 20px;
        }
        p{
         margin-bottom: 28px;
        }

    }
    .service{
        margin-bottom: 100px;
        #accordion{
            margin-left:-32px;
            .card-body {
                padding: 8px 6px 8px 26px;
                .card-header a.collapsed{
                    font-size: 0.875rem;
                    text-transform: capitalize;
                    color: #333;
                    font-weight: 600;
                    line-height: calc(24 / 14);
                    position: relative;
                    }
            }
            .card-header a{
            font-size: 0.875rem;
            text-transform: capitalize;
            color: #fd5f0e;
            font-weight: 600;
            line-height: calc(24 / 14);
            position: relative;
            @media (max-width: 767px){
                margin-left: 30px;
            }
        
            }
        }
        .list1{
            margin: 0px 0 20px 26px;
            @media (max-width: 767px){
                margin-left: 30px;
            }
           li{
            padding-left: 15px;
            margin-bottom: 3px;
            &:before {
               
                font-size: 4px;
           }
        }
        }
        .accodian-list li .card-link.collapsed:before {
            
            top: 8px;
        }
        .domiciliation.collapsed:before {
            content: '';
            background: url(../images/domiciliation-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -26px;
            top: -4px;
            width: 30px;
        }
        
        .domiciliation:before {
            content: '';
            background: url(../images/domiciliation-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -26px;
            top: -4px;
            width: 30px;
        }
        .conseil.collapsed:before {
            content: '';
            background: url(../images/conseil-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -27px;
            top: 0px;
            width: 30px;
        }
        
        .conseil:before {
            content: '';
            background: url(../images/conseil-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -27px;
            top: 0px;
            width: 30px;
        }
    }
    @media (max-width: 767px){
        .accodian-list .card-header a {
            margin-left: 0px !important;
        }
    }
}