body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.group {
  @include clearfix();
}

img {
  max-width: 100%;
}

figure {
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}

h1 {
  font-weight: 300;
  margin-bottom: 20px;
}

h2 {
  font-weight: 300;
}

h3 {
  .message-icon {
    margin-right: 10px;
  }
}

h5 {
  font-weight: 500;
}

p {
  line-height: calc(24 / 14);
  margin-bottom: 23px;
}

@media (max-width: 767px) {
  p {
    font-size: rem-calc(12);
    line-height: calc(22 / 12);
    margin-bottom: 18px;
  }
}


