/*
*news details
*/
.news-details {
  h1 {
    color: $blue;
    font-size: rem-calc(55);
    line-height: calc(70 / 55);
  }
  .meta-data {
    margin-bottom: 47px;
  }
  .column-count2 {
    margin-bottom: 60px;
  }
  .image {
    margin-bottom: 66px;
  }
}
