/*
*buttons
*/
a, .btn {
  @include cubic-transition;
}

.btn {
  line-height: calc(18 / 14);
  border-radius: 0px;
  border-color: transparent;
  font-weight: 500;
  padding: 10px 18px;
}

.btn-primary {
  background-image: -webkit-linear-gradient(right, #fd5f0e 0%, #fd5f0e 100%);
  background-image: -o-linear-gradient(right, #ff9e2d 0%, #fd5f0e 100%);
  background-image: linear-gradient(to left, #ff9e2d 0%, #fd5f0e 100%);
  &:hover {
    background-image: -webkit-linear-gradient(right, #fd5f0e 0%, #ff9e2d 100%);
    background-image: -o-linear-gradient(right, #fd5f0e 0%, #ff9e2d 100%);
    background-image: linear-gradient(to left, #fd5f0e 0%, #ff9e2d 100%);
  }
}

.btn-white {
  color: $orange;
}
