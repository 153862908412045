/*
*container
*/
#container {
  min-width: 320px;
  overflow: hidden;
}

/*
*menu-open
*/
.menu-open {
  overflow: hidden;
  padding-right: 17px;
  position: fixed;
  width: 100%;
  .open-menu-btn {
    .icon-bar {
      opacity: 0;
      position: absolute;
      top: 13px;
      &:first-child {
          opacity: 1;
          transform: rotate(-45deg);
          background-color: $white;
      }
      &:last-child {
          opacity: 1;
          transform: rotate(45deg);
          background-color: $white;
      }
    }
  }
  .menu-overlay {
    opacity: 0.85;
    visibility: visible;
  }
   #main-navigation {
      transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 991px) {
  .menu-open {
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .menu-open {
    .open-menu-btn {
      .icon-bar {
        top: 10px;
      }
    }
  }
}

/*
*header
*/
#header {
  background: $white;
  //border-bottom: 1px solid $primary;
  position: relative;
  z-index: 1000;
  .container-fluid {
    padding: 0px 34px;
  }
  .logo {
      display: inline-block;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
     background-color: $white;
      padding: 21px 34px;
      margin-bottom: -27px;
    }
    .open-menu-btn {
      @include vertical-center;
      right: 30px;
    }
}

@media (max-width: 767px) {
  #header {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
    .logo {
      padding: 13px 21px;
      margin-bottom: -11px;
      img {
        max-width: 165px;
      }
    }
    
    .open-menu-btn {
      right: 15px;
    }
  }
}

/*
*open-menu-bar
*/
.open-menu-btn {
  width: 37px;
  height: 28px;
  padding: 0px;
  background: transparent;
  border: 0px;
  z-index: 1001;
  cursor: pointer;
  .icon-bar {
    width: 100%;
    display: block;
    height: 2px;
    background: $orange;
    margin-bottom: 11px;
    @include cubic-transition;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  &:focus {
    outline: 0px;
  }
}

@media (max-width: 767px) {
  .open-menu-btn {
    width: 30px;
    height: 22px;
    .icon-bar {
      margin-bottom: 8px;
    }
  }
}

/*
*menu overlay
*/
.menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-image: -webkit-linear-gradient(bottom, #002c59 0%, #2f4c72 100%);
  background-image: -o-linear-gradient(bottom, #002c59 0%, #2f4c72 100%);
  background-image: linear-gradient(to top, #002c59 0%, #2f4c72 100%);
  @include cubic-transition;
  z-index: 999;
}

/*
*main navigation
*/
#main-navigation {
  background-image: -webkit-linear-gradient(bottom, #002c59 0%, #2f4c72 100%);
  background-image: -o-linear-gradient(bottom, #002c59 0%, #2f4c72 100%);
  background-image: linear-gradient(to top, #002c59 0%, #2f4c72 100%);
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  padding: 105px 0px 50px;
  transform: translate3d(100%, 0, 0);
  @include cubic-transition;
  .scrollable-content {
    padding: 0px 53px;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .menu {
    padding: 0px;
    list-style-type: none;
    margin: 0px -53px 16px;
    li {
      font-size: rem-calc(20);
      line-height: calc(24 / 20);
      color: $white;
      margin-bottom: 34px;
      &.current {
        a {
          border-color: $orange;
        }
      }
      a {
       /* padding: 21px 53px 21px 43px;*/
        padding: 21px 53px 21px 83px;
        color: inherit;
        display: block;
        border-left: 10px solid transparent;
        &:hover {
          border-color: $orange;
        }
      }
    }

  }
  .contact-details {
    margin-bottom: 75px;
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }
  .divider {
    /*margin: 45px 0 57px;*/
    
      margin: 45px 0 61px 34px;
      @media (max-width: 767px) {
        margin-left: 8px;
      }
 
  }
  h5 {
    color: $white;
    margin-bottom: 39px;
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 8px;
    }
  }
  .form{
    margin-left: 36px;
    @media (max-width: 767px) {
      margin-left: 8px;
    }
  }
}


/*@media (max-width: 991px) {
  #main-navigation {
    width: 434px;
  }
}*/

@media (max-width: 767px) {
  #main-navigation {
    width: 100%;
    padding: 66px 0px 50px;
    .scrollable-content {
      padding: 0px 32px;
    }
    .menu {
      margin: 0px -32px 16px;
      li {
        margin-bottom: 13px;
        a {
          padding-left: 30px;
        }
      }
    }
    .divider {
      margin-top: 31px;
    }
  }
 
}

/*
*banner
*/
#banner {
  overflow: hidden;
  position: relative;
  &.overlay {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: url('../images/banner-before1.svg') repeat-x;
        background-position: top center;
        background-size: cover;
        @media (max-width: 998px){
        background: url("../images/tablet-overlay-blue.svg") repeat-x;
        background-position: top center;
        background-size: contain;
        }
        @media (max-width: 767px){
          background: url("../images/mobile-overlay-blue.svg") repeat-x;
          background-position: top center;
          background-size: contain;
        }
    }
  }
  &.light-overlay {
    &:before {
      background: url('../images/banner-before.svg') repeat-x;
      background-size: cover
    }
    .figure1 {
      img {
        min-height: 407px;
      }
    }
  }
  &.light-overlay-secondary {
    &:before {
      background: url('../images/banner-before2.svg') repeat-x;
      background-position: bottom center;
    
    }
    .figure1 {
      img {
        min-height: 407px;
      }
    }
  }
  .description {
    @include vertical-center;
    width: 100%;
    h1 {
      color: $white;
    }
  }

  .description2 {
    @include vertical-center;
    width: 100%;
    margin-top: -105px;
    @media (max-width: 767px){
      margin-top: -57px;
      width: 320px;
    }
    h1 {
      color: $white;
      position: relative;
      @media (max-width: 992px){
        font-size: rem-calc(60);
        line-height: 77px;
      }
      @media (max-width: 767px){
        font-size: rem-calc(35);
        line-height:47px;
      }
    }
    img{
      position: absolute;
      bottom: -50%;
      left: 20px;
      margin-bottom: -100px;
      @media (max-width: 998px){
        position: absolute;
        bottom: -50%;
        left: 50px;
        margin-bottom: -140px;
      }

      @media (max-width: 767px){
        position: absolute;
        bottom: -50%;
        left: 16px;
        margin-bottom: 70px;
        width: 30px;
        height: 30px;
      }
    }
}

}

@media (max-width: 767px) {
  #banner {
    &.light-overlay {
      .figure1 {
        img {
          min-height: 369px;
        }
      }
    }
  }
}


/*
*footer
*/
#footer {
  padding: 77px 0px 0px;
  background-image: -webkit-linear-gradient(right, #ff931a 0%, #fd5f0e 100%);
  background-image: -o-linear-gradient(right, #ff931a 0%, #fd5f0e 100%);
  background-image: linear-gradient(to left, #ff931a 0%, #fd5f0e 100%);
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 292px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: url('../images/footer-after.svg');
    background-repeat: repeat-x;
    background-position: bottom center;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  h3 {
    color: $white;
    margin-bottom: 55px;
  }
  .dashboard {
    margin-bottom: 180px;
  }
  .form {
    .form-control{
      padding-top: 0px;
    }
    .form-group {
      margin-bottom: 36px;
    }
  }
  .bottom-footer {
    padding-bottom: 35px;
  }
}

@media (max-width: 767px) {
  #footer {
    padding: 33px 0px 0px;
    .contact-details {
      margin-bottom: 72px;
    }
    .dashboard {
      margin-bottom: 182px;
    }
  }
}


.select-language{
  position: absolute;
  right: 70px;
  top: 50%;
  margin-top: -20px;
  @media (max-width: 767px) {
    right: 50px;
    }
  
  .current-language {
  padding: 5px 33px 5px 30px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  color: #2F4C72;
  font-size: rem-calc(14);
  line-height: calc(24/14);
  font-weight: 300;
  @media (max-width: 767px) {
    padding: 5px 3px 5px 3px;
    }
  img{
    @media (max-width: 767px) {
      width:20px;
      height:20px;
      }
  }
  span{
    @media (max-width: 767px) {
      display:none;
      }
  }
}
  i{
    content: '';
    color: #2f4c72;
    font-size: 16px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    z-index: 1;
    @media (max-width: 767px) {
      top: 3px;
      position: relative;

    }
  }
  .languages {
    display: none;
    position: absolute;
    top: 37px;
    left: -14px;
    background: #fff;
    z-index: 5;
    box-shadow: 0 0 15px rgba(0,0,0,0.07);
    width: 170px;
    padding: 10px 25px;
    @media (max-width: 767px) {
      width:40px;
      padding: 5px;
      left: -6px;
    }
      li {
        display: block;
        border-right: none;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    a {
      color: #004785;
      font-size: .875rem;
      line-height: calc(24/14);
      font-weight: 400;
    }
    img{
      width:28px;
      height: 28px;
      @media (max-width: 767px) {
        width:20px;
        height:20px;
        }
    }
    span{
      margin-right: 5px;
      @media (max-width: 767px) {
        display:none;
        }
    }
  }
}

.select-language.open .languages {
  display: block;
}


.form {
    .form-row {
      margin-right: -20px;
      margin-left: -20px;
  }
  .form-row > .col, .form-row > [class*="col-"]{
    padding-right: 20px;
    padding-left: 20px;
  }
}