/*
*box
*/
.box1 {
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.13);
  background-color: $white;
  margin-bottom: 28px;
  .image {
    position: relative;
     &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: url('../images/image-after.svg') no-repeat;
      background-position: bottom left;
      background-size: contain;
    }
  }
  .description {
    padding: 28px 41px 39px;
  }
  h2 {
    font-size: rem-calc(25);
    line-height: calc(35 / 25);
    color: $blue;
    margin-bottom: 22px;
  }
}

@media (max-width: 991px) {
  .box1 {
    .description {
      padding: 28px 29px 37px;
    }
    h2 {
      font-size: rem-calc(22);
      line-height: calc(32 / 22);
    }
  }
}

@media (max-width: 767px) {
  .box1 {
    .description {
      padding: 26px 17px 19px;
    }
    h2 {
      font-size: rem-calc(20);
      line-height: calc(30 / 20);
      margin-bottom: 17px;
    }
  }
}
