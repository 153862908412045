.thanks{
    #banner{
        display: none;
    }
    #header{
        display:none;
    }
    .blue-content{
        
        padding-top: 290px;
        margin-bottom: 190px;
        position: relative;
        padding-bottom: 320px;
        @media (max-width: 767px){
            padding-top: 290px;
            margin-bottom: 90px;
            position: relative;
            padding-bottom: 320px
        }
        &:after{
            content:'';
            background: url(../images/thank-you-overlay.svg) repeat-x top center;
            width: 100%;
            height:340px;
            position: absolute;
            left:0px;
            bottom:-1px;
            z-index: -1;
            background-size: cover;
            @media (max-width: 767px){
                content:'';
                background: url(../images/thanks-mobile-overlay.png) repeat-x top center;
                width: 100%;
                height:300px;
                

            }
        }

        h1{
            color: $white;
            font-size:rem-calc(55);
            font-weight: 300;
            line-height: 70px;
            position: relative;
            &:before{
                content: '';
                background: url(../images/icon-1.svg) repeat-x top center;
                width: 60px;
                height: 60px;
                position: absolute;
                left: 50%;
                top: -80px;
                margin-left: -31px;
                z-index: -1;
                background-size: cover;
        }
        }
        span{
            color: $white;
            font-size:rem-calc(20);
            font-weight: 300;
            line-height: 30px;
        }

        a{
            margin-left: 0px;
            margin-top:60px;
            margin-bottom: 60px;
            @media (max-width: 767px){
                display: block
            }
        }
    }
}

