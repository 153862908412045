.blue-content{
    background-image: -webkit-linear-gradient(left, #002c59 0%, #29486f 100%);
    background-image: -o-linear-gradient(left, #002c59 0%, #29486f 100%);
    background-image: linear-gradient(to right,#002c59 0%, #29486f  100%);
    padding-top: 86px;
    padding-bottom: 190px;
    position: relative;
    z-index: 2;
    @media (max-width: 767px){
        padding-top: 19px;
        padding-bottom: 230px;
    }
    &:after{
        content:'';
        background: url(../images/blue-content-after.png) repeat-x top center;
        width: 100%;
        height:440px;
        position: absolute;
        left:0px;
        bottom:-1px;
        z-index: -1;
        @media (max-width: 767px){
            background: url(../images/blue-content-after-mobile-overlay.png) repeat-x bottom center;
        width: 100%;
        height:200px;
        background-size: contain
        }
    }
    h2{
        font-size: rem-calc(24);
        color: $white;
        font-weight: 400;
        line-height:40px;
        position: relative;
        padding-left: 50px;
        padding-top: 4px;
        @media (max-width: 767px){
            font-size: rem-calc(18);
            padding-left: 47px;
        }
       &::before{
           content:'';
           background: url(../images/icon1.svg) no-repeat 0 0;
           height: 30px;
           position: absolute;
           left: -2px;
           top: 10px;
           width:30px;
       }
    }
    hr{
       margin:1.4rem 0;
       margin-left: 50px;
       border-top:1px solid #fff;
       @media (max-width: 767px){
        margin: 1rem 0;
       }
       
    }
    a{
        margin-left: 50px;
        margin-top: 10px;
        @media (max-width: 767px){
            display:none;
        }
    }
    .btn{
        padding: 10px 26px;
    }
    p{
        color: $white;
        padding: 0px 5px 0px 5px;
        line-height: calc(26 / 14);
    }
}

.our-services{
    margin-bottom: 200px;
    margin-top: 46px;
    @media (max-width: 998px){
        margin-bottom: 50px;
    }
    @media (max-width: 767px){
        margin-top: 52px;
        margin-bottom: 90px;
    }
    h3{
        font-size: rem-calc(24);
        color: #2f4c72;
        font-weight: 400;
        line-height: 40px;
        padding-left: 62px;
        position: relative;
        @media (max-width: 767px){
            padding-left: 47px;
        }
        &::before{
            content:'';
            background: url(../images/icon1-blue.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: 9px;
            top: 6px;
            width:30px;
            @media (max-width: 767px){
                left: 0px;
            }
        }

    }
    .services-content{
        margin-left: 42px;
        margin-top: 56px;
        @media (max-width: 767px){
            margin-left: 12px;
            margin-top: 41px;
        }

        
    }
}


.latest-news-section{
    padding-bottom: 62px;
    position: relative;
    z-index: 2;
    @media (max-width: 767px){
        margin-top: 0px;
        padding-bottom: 40px;
    }
    &:after{
        content:'';
        background: url(../images/latest-news-overlay.svg) no-repeat top center;
        width: 100%;
        height:100%;
        position: absolute;
        left:0px;
        bottom:-1px;
        z-index: -1;
        background-size: cover;
        @media (max-width: 998px){
            content: '';
            background: url(../images/latest-news-tablet-overlay.svg) no-repeat right center;
            background-size: cover;
        }
        @media (max-width: 767px){
            content: '';
            background: url(../images/latest-news-mobile-overlay.svg) repeat-x bottom center;
            background-size: contain;
            height:330px;
           
        }
    }
    h3{
        font-size: rem-calc(24);
        color: #2f4c72;
        font-weight: 400;
        line-height: 40px;
        padding-left: 57px;
        position: relative;
        @media (max-width: 767px){
            margin-top: 0px
        }
        &::before{
            content:'';
            background: url(../images/latest-news-icon.svg) no-repeat 0 0;
            height: 31px;
            position: absolute;
            left: 4px;
            top: 9px;
            width: 30px;
        }

    }
    h4{
        font-size: 2.875rem;
    color: #fff;
    font-weight: 300;
    line-height: 60px;
    padding-left: 109px;
    padding-top: 56px;
    position: relative;
    @media (max-width: 998px){
        font-size: 2.25rem;
        line-height: 50px;
        padding-top: 40px;
        padding-left: 32px;
        padding-right: 40px;
    }
    @media (max-width: 767px){
        font-size: rem-calc(28);
        padding-left: 0px;
        line-height: 39px;
        padding-top: 40px;
        margin-top: 120px;
        padding-right: 0px;
    }
    }
}

.news-section{
    margin-bottom: 50px;
    .left{
       
        .photo-bg{
            width: 100%;
            position: relative;
            img{
                width: 100%;
                height: 308px;
                position: relative;
            }
        } 
    }
}

.news-panel{
    background-color: #fff;
    -webkit-box-shadow: 2px 2px 3px 1px rgba(194,194,194,0.3);
    -moz-box-shadow: 2px 2px 3px 1px rgba(194,194,194,0.3);
    box-shadow: 2px 2px 3px 1px rgba(194,194,194,0.3);
    background-color: #fff;
    margin-left: -15px;
    max-width: 45%;
    @media (max-width: 989px){
        max-width: 100%;
    }
    @media (max-width: 767px){
        margin-left: 25px;
        margin-right: 25px;
        margin-top: -45px;
        -webkit-box-shadow: 0px 2px 6px 2px rgba(194,194,194,0.3);
        -moz-box-shadow: 0px 2px 6px 2px rgba(194,194,194,0.3);
        box-shadow: 0px 2px 6px 2px rgba(194,194,194,0.3);
        max-width: 100%;
    }

    .description{
        padding: 46px 84px 41px 44px;
        @media (max-width: 998px){
            padding: 46px 44px 41px 44px;
        }
        @media (max-width: 767px){
            padding: 20px 0px 20px 0px;
        }
        .meta-data {
            font-weight: 600;
            font-size: 0.75rem;
            margin-bottom: 13px;
        }
        p{
            margin-bottom: 28px
        }
        .btn{
            padding:10px 24px;
        }
    }
    hr{
        padding: 1rem 0rem
    }
}

.view-all{
    margin-bottom: 58px;
    h3{
        font-size: 1.5rem;
        color: #2f4c72;
        font-weight: 400;
        line-height: 40px;
        padding-left: 67px;
        position: relative;
        margin-top: 0px;
        margin-bottom: 0px;
        &::before{
            content: '';
            background: url(../images/view-all-icon.svg) no-repeat 0 0;
            height: 38px;
            position: absolute;
            left: 4px;
            top: 2px;
            width: 38px;
        }
    }
    
    .btn{
        padding: 10px 36px;
        margin-top: 0px;
        float: right;
        @media (max-width: 767px){
            width:100%;
            margin-top: 10px;
            margin-bottom: 5px;

        }
    }
    }

.mbl{
    display: none;
    @media (max-width: 767px){
        width:100%;
        margin-top: 10px;
        margin-bottom: 5px;
        display:block !important;
        margin-left: 0px !important;

    }
}