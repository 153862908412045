/*
*form
*/
.form {
  .form-group {
    margin-bottom: 42px;
  }
  .form-control {
    background: transparent;
    border-radius: 0px;
    border-color: transparent;
    font-size: rem-calc(14);
    line-height: calc(20 / 14);
    color: $white;
    border-bottom-color: $white;
    padding: 8px 0px 8px;
    resize: none;
    font-weight: 500;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $white;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $white;
    }
    &:focus {
      box-shadow: none;
    }
  }
  textarea {
    min-height: 30px;
  }
}
