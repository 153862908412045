/*
*post
*/
.post {
   .meta-data {
    font-weight: 600;
    font-size: rem-calc(12);
    margin-bottom: 16px;
    .separator {
        display: inline-block;
        margin: 0px 18px;
    }
  }
}

@media (max-width: 767px) {
  .post {
    .meta-data {
      font-size: rem-calc(11);
    }
  }
}
