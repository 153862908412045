/*
*icon
*/
.icon {
  display: inline-block;
  vertical-align: middle;
}

.location-icon {
  background: url('../images/location-icon.svg') no-repeat;
  width: 21px;
  height: 29px;
}

.phone-icon {
  background: url('../images/phone-icon.svg') no-repeat;
  width: 26px;
  height: 26px;
}

.fax-icon {
  background: url('../images/fax-icon.svg') no-repeat;
  width: 24px;
  height: 24px;
}

.message-icon {
  background: url('../images/message-icon.svg') no-repeat;
  width: 43px;
  height: 20px;
}
