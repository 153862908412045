.divider {
  border-top-color: rgba($white, .23);
  margin: 51px 0;
}

.divider2 {
  border-top-color: #cbcbcb;
  margin: 38px 0px;
}

.figure1 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media (max-width: 998px) {
  .figure1 {
    height: 647px;
  }
}
@media (max-width: 767px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
  .figure1 {
    height: 385px;
  }
}

@media (min-width: 992px) {
  .column-count2 {
    column-count: 2;
  }
  
  
}
