.news-detail-withphoto-page #banner.light-overlay-secondary .figure1 img {
    min-height: 402px;
}

.news-details-withphoto-section{
        margin-top: -47px;
        padding-top: 0px;
        position: relative;
        z-index: 3;
        h1 {
          color: $blue;
          font-size: rem-calc(55);
          line-height: calc(70 / 55);
          @media (max-width: 998px){
            font-size: rem-calc(36);
            line-height: calc(42 / 36);
          }
          @media (max-width: 767px){
            font-size: rem-calc(24);
            line-height: calc(30 / 24);
          }
        }
        .meta-data {
            margin-bottom: 54px;
        }
        .column-count2{
            margin-bottom: 59px;
        }
        figure {
            margin-bottom: 72px;
        }
        .btn{
            @media (max-width: 767px){
               width: 100%
              }
        }
        .pager{
            @media (max-width: 767px){
                width: 100%
            }
        }
        
}

.news-detail-withoutphoto-page{
    #banner{
        height:400px;
        background-color: #ebebeb;
        padding-top: 90px;
    span{
        color: #bfbfbf;
        font-size: 30px;
        font-weight: 700;
        line-height: 70px;
        text-transform: uppercase;
    }
}
}