/*
*contact-details
*/
.contact-details {
  padding: 0px;
  list-style-type: none;
  li {
    color: $white;
    font-size: rem-calc(14);
    line-height: calc(20 / 14);
    font-weight: 500;
    margin-bottom: 31px;
    padding-left: 47px;
    position: relative;
    .icon {
      width: 26px;
      background-position: center center;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    a {
      color: inherit;
    }
  }
}
