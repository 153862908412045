.card{
    border:0px;
    margin-bottom: 50px
}
.card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color:#fff;
        border-bottom: 0px;
        a{
            color: #fd5f0e;
            font-size: rem-calc(13);
            font-weight: 500;
            line-height: 21px;
            position: relative;
            padding-left: 5px;
            display:block;
            &.collapsed{
                color: #2f4c72;
                &:after{
                    content: '\f107';
                    color: #2f4c72;
                    font-size: 20px;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 400;
                    position: absolute;
                    right: -20px;
                }
            }
            &:after{
                content: '\f106';
                color: #ff7418;
                font-size: 20px;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 400;
                position: absolute;
                right: -20px;
                top: 0;
            }
        }
}
.card-body {
    padding: 8px 6px 8px 50px;
    @media (max-width: 767px){
        padding: 8px 6px 8px 10px;
    }
}
.card-body{
    h5{
        font-size: rem-calc(14);
        text-transform: uppercase;
        color: #2f4c72;
        font-weight: 600;
        line-height: calc(24 / 14);
    }
}
.conseil{
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/conseil-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -27px;
        top: 0px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/conseil-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -27px;
            top: 0px;
            width: 30px;

        }

}

.domiciliation{
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/domiciliation-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -35px;
        top: -4px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/domiciliation-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -35px;
            top: -4px;
            width: 30px;

        }

}


.experts{
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/experts-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -31px;
    top: 5px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/experts-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -31px;
    top: 5px;
            width: 30px;

        }

}

.gestion{
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/gestion-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -34px;
        top: -3px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/gestion-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -34px;
            top: -3px;
            width: 30px;

        }

}

.diagnostic{
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/diagnostic-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -34px;
        top: -3px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/diagnostic-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -34px;
            top: -3px;
            width: 30px;

        }

}

.le-secretariat {
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/le-secretariat-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -34px;
        top: -3px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/le-secretariat-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -34px;
            top: -3px;
            width: 30px;

        }

}

.la-constitution {
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/la-constitution-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -34px;
        top: -3px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/la-constitution-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -34px;
            top: -3px;
            width: 30px;

        }

}

.ingenierie {
    &.collapsed{
    &:before{
        content: '';
        background: url(../images/ingenierie-icon.svg) no-repeat 0 0;
        height: 30px;
        position: absolute;
        left: -40px;
        top: -2px;
        width: 30px;
    }
}
        &:before{
            content: '';
            background: url(../images/ingenierie-icon-active.svg) no-repeat 0 0;
            height: 30px;
            position: absolute;
            left: -40px;
            top: -2px;
            width: 30px;

        }

}

.accodian-list{
    margin:0px;
    padding: 0px;
    list-style: none;
    li{
        border-top: 1px solid #d1d8e0;
        position: relative;
        .card-link{
                
                &:before{
                    content: '\f111';
                        color: #ff7418;
                        font-size: 4px;
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 600;
                        position: absolute;
                        z-index: 1;
                        left: -10px;
                        top:2px;
            }
        &.collapsed{
            &:before{
                content: '\f111';
                    color: #2f4c72;
                    font-size: 4px;
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 600;
                    position: absolute;
                    z-index: 1;
                    left: -10px;
                    top:2px;
            }
        }
    }
        .card{
            margin-bottom: 0px;
            .card-body1{
                p{
                    padding: 0px 20px 0 5px;
                }
            }
        }
    }
}

.list1{
    margin: 0px 0 20px 10px;
    padding: 0px;
    list-style: none;
    li{
        margin: 0px;
        position: relative;
        border-top: 0px solid #d1d8e0;
        &:before{
            content: '\f111';
                color: #2f4c72;
                font-size: 6px;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 600;
                position: absolute;
                z-index: 1;
                left: -10px;
                top:9px;
        }
    }
}

