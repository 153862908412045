/*
*section
*/
.section {
  padding: 60px 0px;
  .grid {
    margin-bottom: 74px;
  }
  .pagination {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .section {
    .grid {
      margin-bottom: 60px;
    }
    .pagination {
      margin-bottom: 17px;
    }
  }
}

@media (max-width: 767px) {
  .section {
    padding: 30px 0px;
    .grid {
      margin-bottom: 41px;
    }
    .pagination {
      margin-bottom: 55px;
    }
  }
}
